import * as React from 'react';

const SendIcon = () => {

    return (
        <>
            <img
                src="Rocket.svg"
                alt="Rocket"
                style={{
                    width:"20px",
                    height:"20px"
                }}
            />
        </>
    );
};

export default SendIcon;